import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout/layout.jsx';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { tnmuLogo } from './tnmu-ukraine.module.scss'
import { motion } from 'framer-motion';
import Button from "@mui/material/Button";
import { StaticImage } from 'gatsby-plugin-image';
import SEO from "../../components/seo"

const tnmuLink = "https://www.tdmu.edu.ua/"
export const query = graphql`
  query LocalesTNMU($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default function TNMU() {
    const translate = useTranslation().t;
    return (
        <Layout>
          <SEO title="TNMU"/>
           <StaticImage style={{margin: "0 auto"}} layout="fixed" width={400} height={400} src="../../images/tnmu-logo.png"/>
            <div class="inner" style={{ width: "90%", marginTop: "3rem", paddingBottom: "1rem"}}>
                <Typography sx={{ marginTop: "1rem", marginBottom: "3rem", marginLeft: "auto", marginRight: "auto", whiteSpace: "pre-line" }} >
                    {translate("tnmu-description")}
                </Typography>
                <motion.button
                            style={{ border: "none", margin: "auto", display: "flex" }}
                            whileHover={{
                                scale: 1.1,
                            }}
                            whileTap={{ scale: 0.9 }}
                        >
                            {
                                    <Button href={tnmuLink} target="_blank" size="large" variant="contained" color="secondary" >
                                        <Typography align="center" color="primary" variant="button">
                                        {translate("visit-website")}
                                        </Typography>
                                    </Button>
                            }
                        </motion.button>
            </div>
        </Layout>
  )
}
